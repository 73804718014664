.not__found__wrapper {
	margin-top: 3rem;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.not__found__wrapper h1 {
	color: white;
}

.not__found__wrapper h2 {
	margin-top: 3rem;
	color: white;
}
