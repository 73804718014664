:root {
    --clr-neon: hsl(317 100% 54%);
    --clr-bg: hsl(323 21% 16%)
}

.neons {
    font-size: 25px;
    height: 60px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: "Balsamiq Sans", cursive;
    text-decoration: none;
    color: var(--clr-neon);
    border: var(--clr-neon) 3px solid;
    background-color: transparent;
    border-radius: 0.25em;
    text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em currentColor;
    box-shadow: inset 0 0 0.5em 0 var(--clr-neon), 0 0 0.5em 0 var(--clr-neon);
    transition: all 0.4s
}

.neons:hover {
    background-color: var(--clr-neon);
    color: #fff
}