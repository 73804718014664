.red{
  color: red;
}

:root {
  --clr-neon: hsl(317 100% 54%);
  --clr-bg: hsl(323 21% 16%)
}

.inverted{
  -webkit-filter: invert(100%)
}

.contact__wrapper{
  margin: 1rem;
  display: flex;
  flex-direction: column;
}

.contact__links{
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.contact__links a{
  margin: 1rem;
}