.glow__pink {
	color: white;
}

.big__size {
	font-size: 6rem;
}

.med__size {
	font-size: 3rem;
}

.Home {
	font-family: 'Pattaya', sans-serif;
}

.skills_wrapper {
	width: 500px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
}

.title__wrapper {
	width: 20%;
}

.white {
	color: white !important;
}

.skills__wrapper {
	width: 100%;
	border: 1px solid white;
	padding: 10px;
	border-radius: 5px;
	margin: 10px;
}

.dyn__element {
	color: white;
}

.dyn__element:hover {
	text-shadow: 0 0 3px #0061fe, 0 0 7px #0061fe, 0 0 20px #0061fe;
	color: #0061fe;
	cursor: pointer;
}
.img__wrapper {
	position: absolute;
}

.interactive__item {
	margin-bottom: 10px;
}

.hidden__badg1 {
	display: none !important;
}

.interactive__item:hover {
	box-shadow: 0 0 5px #ffe205, 0 0 7px #ffe205, 0 0 20px #edd205;
	color: #ffe205;
	width: fit-content;
	opacity: 0.8;
}

.small__text {
	font-size: 15px !important;
	color: white !important;
	text-shadow: 0 !important;
}

.medium__text {
	font-size: 25px !important;
	color: white !important;
}

.medium__text:hover {
	font-size: 25px !important;
}

.tech__wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;

	margin: 0;
}

.tech__wrapper p {
	margin: 0 10px;
}

.sign {
	min-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&__word {
		font-size: 5.6rem;
		text-align: center;
		line-height: 1;
		color: #c6e2ff;
		animation: neon 0.08s ease-in-out infinite alternate;
	}
}

/*-- Animation Keyframes --*/

@keyframes neon {
	from {
		text-shadow: 0 0 6px rgba(202, 228, 225, 0.92),
			0 0 30px rgba(202, 228, 225, 0.34), 0 0 12px rgba(253, 72, 142, 0.52),
			0 0 21px rgba(253, 72, 142, 0.92), 0 0 34px rgba(253, 72, 142, 0.78),
			0 0 54px rgba(253, 72, 142, 0.92);
	}
	to {
		text-shadow: 0 0 6px rgba(202, 228, 225, 0.98),
			0 0 30px rgba(202, 228, 225, 0.42), 0 0 12px rgba(253, 72, 142, 0.58),
			0 0 22px rgba(253, 72, 142, 0.84), 0 0 38px rgba(253, 72, 142, 0.88),
			0 0 60px rgba(253, 72, 142, 1);
	}
}

.link {
	position: absolute;
	bottom: 10px;
	left: 10px;
	color: #828282;
	text-decoration: none;

	&:focus,
	&:hover {
		color: #c6e2ff;
		text-shadow: 0 0 2px rgba(202, 228, 225, 0.92),
			0 0 10px rgba(202, 228, 225, 0.34), 0 0 4px rgba(30, 132, 242, 0.52),
			0 0 7px rgba(30, 132, 242, 0.92), 0 0 11px rgba(30, 132, 242, 0.78),
			0 0 16px rgba(30, 132, 242, 0.92);
	}
}
