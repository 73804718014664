.NavBar{
    background-color: var(--nav-color) !important;
    font-family: 'Pattaya', sans-serif;
}

.color__ov{
    color: white !important;
}

.nav__item{
    font-size: 1.3rem;
}

.nav__item:hover{
    color: var(--pink-color) !important;
}