@import url('https://fonts.googleapis.com/css2?family=Pattaya&display=swap');

:root {
  --background-color: #181818;
  --nav-color: #373737;
  --nav-transparent: rgba(55,55,55,0.3);
  --pink-color: rgb(253, 72, 142);
  
}


body{
  background-color: var(--background-color) !important;
}

.glow__yellow{
  text-shadow:0 0 3px #FFE205, 0 0 7px #FFE205, 0 0 20px #EDD205;color:#FFE205;
  
}

.glow__red{
  text-shadow:0 0 3px #ff0505, 0 0 7px #ff0505, 0 0 20px #ff0505;color:#ff0505;
  
}

.glow__pink{
  text-shadow:0 0 2px #fd488e,0 0 30px #fd488e,0px 0px 5px #fd488e, 0 0 150px #fd488e;color:#fd488e;
}

.glow__change{
  color:white;
}
.glow__change:hover{
  transition: 0.9s;
  text-shadow:0 0 2px #fd488e,0 0 30px #fd488e,0px 0px 5px #fd488e, 0 0 150px #fd488e;color:#fd488e;
  cursor: pointer;
}

.glow__blue{
  text-shadow:0 0 3px #0061FE, 0 0 7px #0061FE, 0 0 20px #0061FE;color:#0061FE;
}

.glow__white{
  text-shadow:0 0 3px #ffffff, 0 0 7px #ffffff, 0 0 20px #ffffff;color:#ffffff;
}

.light__overlie{
  background-color: var(--nav-transparent);
}